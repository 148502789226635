const DoubleVerticalDotsIcon = () => {
  return (
    <svg
      className="m-2 ml-3"
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1" cy="1" r="1" fill="#1F2937"></circle>
      <circle cx="6" cy="1" r="1" fill="#1F2937"></circle>
      <circle cx="1" cy="5" r="1" fill="#1F2937"></circle>
      <circle cx="1" cy="9" r="1" fill="#1F2937"></circle>
      <circle cx="6" cy="5" r="1" fill="#1F2937"></circle>
      <circle cx="6" cy="9" r="1" fill="#1F2937"></circle>
    </svg>
  )
}

export default DoubleVerticalDotsIcon
